import Checkout from 'components/Checkout'
import Header from 'components/Header'

const CheckoutPage = () => {
  return (
    <>
      <Header />
      <Checkout />
    </>
  )
}

export default CheckoutPage
